module services {
    export module logistics {
        export class manifestSundryItemService implements interfaces.manifest.IManifestSundryItemService {
            static $inject = ["$resource", "ENV"];

            constructor(private $resource: ng.resource.IResourceService, private ENV: interfaces.applicationcore.serverConfig) {
            }

            delete(msiId: number): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "ManifestSundryItem/Delete", {
                    msiId: msiId
                });
            }

            save(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "ManifestSundryItem/Save");
            }

            get(msiId: number, manId?: number): ng.resource.IResourceClass<interfaces.manifest.IManifestSundryItem> {
                return this.$resource<interfaces.manifest.IManifestSundryItem>(this.ENV.DSP_URL + "ManifestSundryItem/Get", {
                    msiId: msiId,
                    manId: manId
                }, {
                        get: {
                            method: "GET",
                            isArray: false,
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates }
                        }
                    });
            }
        }
    }
    angular.module("app").service("manifestSundryItemService", services.logistics.manifestSundryItemService);
}